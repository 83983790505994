<template>
  <div>
    <h2 class="">404</h2>
    <div>{{ $t('404.MESSAGE') }}</div>
  </div>
</template>

<script>
export default {
  name: 'P404',
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    
  }
}
</script>